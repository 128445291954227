// src/Home.js
import React from 'react';
import '../css/HomePage.css';
import Bilde from '../images/Klementin.jpeg';
//import './Home.css';

const Home = () => {
    return (
        <div className="home">
            <h1>Velkomen til intern klementin-challenge!</h1>
            <img src={Bilde} alt="Klementin i båter" className="home-image" />
            <p>Klementinbåt-challenge er enkelt. Kor mange båtar er det i klementinen din? Og klarar du å ete alle?</p>
        </div>
    );
};

export default Home;
