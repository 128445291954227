import React from 'react';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};


function formatDateInNorwegian(timestamp) {
    const date = new Date(timestamp);

    // Use Intl.DateTimeFormat to format the date in Norwegian
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const norwegianDateFormatter = new Intl.DateTimeFormat('nb-NO', options);

    return norwegianDateFormatter.format(date);
}

// Forslag til korleis det kan sjå ut
// eslint-disable-next-line
const Score2 = ({ name, score, date, isVerified }) => {
    return (
        <div className="score-item">
            <div className="score-details">
                <strong></strong>
                <span className="score-name"><strong>{name}: </strong></span>
                <span className="score-value"><strong>{score}</strong></span>
                <span className="score-date"><strong>{formatDate(date)}</strong></span>
            </div>
            {isVerified && <span className="score-verified"> &#x2705;</span>}
        </div>
    );
};

// The Score component
const Score = ({ name, score, date, isVerified }) => {
    return (
        <div className="score-item">
            <div className="score-details">
                <strong>{name} med {score} båtar - {formatDateInNorwegian(date)}</strong>
            </div>
            {isVerified && <span className="score-verified"> &#x2705;</span>}
        </div>
    );
};


export default Score;
